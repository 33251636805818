import { Socnet } from '@/types';
import classNames from 'classnames';
import { useSetSelectedServiceValueFormState } from '@/atoms/form/selected-service-value-form-state';
import { useIsAppNavOpenedValue, useSetOpenedPopupsState } from '@/atoms/opened-popups';
import { FEEDBACK_POPUP_NAME } from '../FeedbackPopup/FeedbackPopup';
import Button from '@/components/shared/Button';
import PhoneButton from '../PhoneButton';
import { YD_NEW_ID } from '@/metrics/ym';
import { useSetFeedbackPopupDataNameState } from '../FeedbackPopup/use-feedback-popup-data-name-atom';
import { tp } from '@/typograf';
import Link from '@/components/shared/Link';
import { useCallbackLinkValue } from '@/atoms/catalog-detail/callback-link';
import { useVideoFullscreenValue } from '@/atoms/video-fullscreen';

const CallbackAndPhoneButtons = ({
    phone,
    socnets,
    subscribeLink,
}: {
    phone: string;
    socnets: Socnet[];
    subscribeLink: string;
}) => {
    const { openPopup } = useSetOpenedPopupsState();
    const isAppNavPopupOpened = useIsAppNavOpenedValue();
    const isCallbackLink = useCallbackLinkValue();
    const isVideoFullscreen = useVideoFullscreenValue();
    const setFeedbackPopupDataName = useSetFeedbackPopupDataNameState();
    const setServiceValueFormState = useSetSelectedServiceValueFormState();

    return (
        <div
            className={classNames('callback-and-phone-buttons', {
                'callback-bottom': isVideoFullscreen,
            })}
        >
            {isCallbackLink ? (
                <Button
                    tag={Link}
                    href={subscribeLink}
                    target="_blank"
                    variant="primary"
                    className={classNames('app-nav-callback-button app-nav-callback-button--tablet', {
                        'app-nav--burger--opened': isAppNavPopupOpened,
                    })}
                >
                    {tp('Подписчикам Telegram скидка от 200$ - 1000$')}
                </Button>
            ) : (
                <Button
                    variant="primary"
                    className={classNames('app-nav-callback-button app-nav-callback-button--touch', {
                        'app-nav--burger--opened': isAppNavPopupOpened,
                    })}
                    onClick={() => {
                        setServiceValueFormState('под заказ');
                        setFeedbackPopupDataName('order');
                        openPopup(FEEDBACK_POPUP_NAME);
                        window.ym?.(YD_NEW_ID, 'reachGoal', 'click_on_request');
                    }}
                >
                    {tp('Сервис «Под заказ»')}
                </Button>
            )}

            <PhoneButton socnets={socnets} phone={phone} subscribeLink={subscribeLink} />
        </div>
    );
};

export default CallbackAndPhoneButtons;
